import { createHelpers, getField, updateField } from "vuex-map-fields";

export const { mapFields: mapFocalAreasFields } = createHelpers({
  getterType: "focalAreas/getField",
  mutationType: "focalAreas/updateField",
});

function initialFocalAreasState() {
  return {
    focalAreas: [],
  };
}
export default {
  namespaced: true,
  state: initialFocalAreasState,
  getters: {
    getField,
  },
  mutations: {
    addFocalAreaDataPulled(state, { focalAreaIdx, dataPulled }) {
      state.focalAreas[focalAreaIdx].dataPulled = dataPulled;
    },
    addFocalAreaMajorDomos(state, { focalAreaIdx, majorDomos }) {
      state.focalAreas[focalAreaIdx].majorDomos = majorDomos;
    },
    addFocalAreaProjects(state, { focalAreaIdx, projects }) {
      state.focalAreas[focalAreaIdx].projects = projects;
    },
    addFocalAreaResources(state, { focalAreaIdx, resources }) {
      state.focalAreas[focalAreaIdx].resources = resources;
    },
    addFocalAreaCustomResources(state, { focalAreaIdx, customResources }) {
      state.focalAreas[focalAreaIdx].customResources = customResources;
    },
    updateField,
    resetFocalAreasStore(state) {
      const s = initialFocalAreasState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
};
