import { createHelpers, getField, updateField } from "vuex-map-fields";

export const { mapFields: mapUserFields } = createHelpers({
  getterType: "user/getField",
  mutationType: "user/updateField",
});

function initialUserState() {
  return {
    bio: "",
    email: "",
    firstName: "",
    focalAreas: [],
    groups: [],
    lastName: "",
    nickname: "",
    phone: "",
    title: "",
    username: "",
    userType: "",
    vendorAwaitingApproval: false,
  };
}

export default {
  namespaced: true,
  state: initialUserState,
  getters: {
    getField,
  },
  mutations: {
    updateField,
    resetUserStore(state) {
      const s = initialUserState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
};
