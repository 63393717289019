import { createHelpers, getField, updateField } from "vuex-map-fields";

export const { mapFields: mapAppFields } = createHelpers({
  getterType: "app/getField",
  mutationType: "app/updateField",
});

function initialAppState() {
  return {
    bannerBody: "",
    bannerClosed: false,
    bannerDefault: false,
    bannerHeader: "",
    bannerPulled: false,
    bannerType: "",
    changeDate: "",
    dataPulled: false,
    errorOccurred: false,
    firstVisitNewDashboard: false,
    lastLogin: "",
    loading: true,
    notAuthorized: false,
    mobileNavVisible: false,
    techPubCategoryOptions: [],
    techPubDocTypeOptions: [],
    techPubResponse: [],
    techPubSubcategoryOptions: [],
    showDashboardNav: false,
    token: "",
    userAuthenticated: false,
    userAuthorized: true,
  };
}

export default {
  namespaced: true,
  state: initialAppState,
  getters: {
    getField,
  },
  mutations: {
    updateField,
    resetAppStore(state) {
      const s = initialAppState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    updateErrorOccurred(state, value) {
      state.errorOccurred = value;
    },
    updateLoading(state, value) {
      state.loading = value;
    },
    updateNotAuthorized(state, value) {
      state.notAuthorized = value;
    },
    updateMobileNavVisible(state, value) {
      state.mobileNavVisible = value;
      if (value === false) {
        document.querySelector("html").style.overflowY = "auto";
      }
    },
    updateShowDashboardNav(state, value) {
      state.showDashboardNav = value;
    },
    updateUserAuthenticated(state, value) {
      state.userAuthenticated = value;
    },
    updateUserAuthorized(state, value) {
      state.userAuthorized = value;
    },
  },
};
