import router from "@/router";
import EmailService from "@/services/EmailService";
import store from "@/state";

export default async function (error) {
  if (
    error.code === "ECONNABORTED" ||
    (error.response &&
      (!error.response.request.responseURL.includes("/check-login-state") ||
        error.response.data.error.code !== "ENTITY_NOT_FOUND"))
  ) {
    await store.commit("app/updateErrorOccurred", true);
    router.push("/error");

    const data = error.response ? error.response.data : error.stack;
    const status = error.response ? error.response.status : error.code;
    const statusText = error.response
      ? error.response.statusText
      : error.message;
    const responseUrl = error.response ? error.response.responseUrl : "";
    const configData = error.response ? error.response.configData : "";
    const userData = error.response
      ? {
          bio: store.state.user.bio,
          clLoginId: store.state.user.username,
          changeDate: store.state.app.changeDate,
          email: store.state.user.email,
          firstName: store.state.user.firstName,
          jobTitle: store.state.user.title,
          lastName: store.state.user.lastName,
          phone: store.state.user.phone,
        }
      : {};

    EmailService.sendErrorEmail({
      data,
      status,
      statusText,
      responseUrl,
      configData,
      userData,
    });
  }
  return Promise.reject(error);
}
