import { createHelpers, getField, updateField } from "vuex-map-fields";

export const { mapFields: mapCompanyFields } = createHelpers({
  getterType: "company/getField",
  mutationType: "company/updateField",
});

function initialCompanyState() {
  return {
    accountId: "",
    companyBlockCopy: "",
    companyName: "",
    companyType: "",
    companyUserCount: 0,
    hasNdas: false,
    parentNdas: [],
    vendorNdas: [],
    workgroups: [],
    workgroupsPulled: false,
  };
}
export default {
  namespaced: true,
  state: initialCompanyState,
  getters: {
    getField,
  },
  mutations: {
    updateField,
    resetCompanyStore(state) {
      const s = initialCompanyState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
};
