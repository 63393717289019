<template>
  <ul class="accordion">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      AccordionWrapper: {
        count: 0,
        active: null,
      },
    };
  },
  provide() {
    return { AccordionWrapper: this.AccordionWrapper };
  },
};
</script>
