import CrowdService from "@/services/CrowdService";
import SalesforceService from "@/services/SalesforceService";
import { mapCompanyFields } from "@/state/modules/company";
import { mapUserFields } from "@/state/modules/user";

export default {
  computed: {
    ...mapCompanyFields(["accountId"]),
    ...mapUserFields([
      "bio",
      "email",
      "firstName",
      "groups",
      "lastName",
      "nickname",
      "phone",
      "title",
      "username",
    ]),
  },

  methods: {
    async getUserData() {
      if (!this.username || !this.email) {
        const crowdResponse = await CrowdService.crowdLookup();

        if (crowdResponse && crowdResponse.data) {
          this.username = crowdResponse.data.username;
          this.groups = crowdResponse.data.groups
            ? crowdResponse.data.groups
            : [];

          const sfResponse = await SalesforceService.usernameLookup({
            cablelabsLogin: crowdResponse.data.username,
          });

          if (sfResponse && sfResponse.data) {
            this.accountId = sfResponse.data.accountId;
            this.bio = sfResponse.data.bio;
            this.email = sfResponse.data.email;
            this.firstName = sfResponse.data.firstName;
            this.lastName = sfResponse.data.lastName;
            this.nickname = sfResponse.data.nickname;
            this.phone = sfResponse.data.phone;
            this.title = sfResponse.data.title;
          }
        }
      }
    },
  },
};
