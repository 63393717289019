import axios from "axios";
import axiosRetry from "axios-retry";
import { errorNotifier } from "./interceptors";

const GatedApi = axios.create({
  baseURL: `${process.env.VUE_APP_SERVER_BASE}/gated/api`,
  withCredentials: true,
  crossDomain: true,
  insecureHTTPParser: true,
  timeout: 15000,
});

axiosRetry(GatedApi, {
  retryDelay: axiosRetry.exponentialDelay,
  retries: 2,
});

GatedApi.interceptors.response.use(
  (response) => response,
  (error) => {
    errorNotifier(error);
  },
);

export default GatedApi;
