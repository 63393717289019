<template>
  <li class="accordion__item">
    <div
      class="accordion__trigger"
      :class="{ 'accordion__trigger--active': visible }"
      @click="open"
      role="button"
      :aria-expanded="visible"
      :aria-controls="`collapse-${panelId}`"
    >
      <h2 class="accordion__trigger-title">
        <slot name="accordion-trigger"></slot>
      </h2>
    </div>
    <transition
      name="accordion-wrapper"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div
        class="accordion__content"
        v-show="visible"
        :id="`collapse-${panelId}`"
      >
        <slot name="accordion-content"></slot>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  inject: ["AccordionWrapper"],

  props: ["routeWhenOpen", "type"],

  data() {
    return {
      visible: false,
      panelId: this._.uid,
    };
  },

  watch: {
    /* eslint-disable no-unused-vars */
    $route(to, from) {
      if (to.path.includes(this.routeWhenOpen) && this.type === "nav-item") {
        this.visible = true;
      }
    },
    /* eslint-enable no-unused-vars */
  },

  methods: {
    open() {
      this.visible = !this.visible;
    },
    beforeEnter: function (el) {
      el.style.height = "0";
      el.style.opacity = "0";
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + "px";
      el.style.opacity = "1";
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + "px";
    },
    leave: function (el) {
      el.style.height = "0";
      el.style.opacity = "0";
    },
  },
};
</script>
