export default {
  methods: {
    breakTagDelimStrToArr(str) {
      if (!str) {
        return [];
      } else if (str.includes("<br>")) {
        return str.split("<br>");
      } else {
        return [`${str}`];
      }
    },
    commaDelimStrToArr(str) {
      if (!str) {
        return [];
      } else if (str.includes(";")) {
        return str.split(";");
      } else {
        return [`${str}`];
      }
    },
    slugify(text) {
      return text
        .toString() // Cast to string (optional)
        .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(/[^\w-]+/g, "") // Remove all non-word chars
        .replace(/--+/g, "-"); // Replace multiple - with single -
    },
  },
};
