<template>
  <div />
</template>

<script>
import "cookieconsent";

export default {
  name: "CookieConsent",
  created() {
    const cookieConsentEl = document.querySelector(
      "[aria-label='cookieconsent']",
    );

    // Hack to prevent multiple cookie consent elements from getting created
    if (cookieConsentEl) {
      cookieConsentEl.remove();
    }

    window.cookieconsent.initialise({
      enabled: true,
      cookie: {
        domain: ".cablelabs.com",
        expiryDays: 365,
      },
      // Message and button text can be customized
      content: {
        message:
          "CableLabs.com uses cookies to provide you the best experience.",
        dismiss: "Got it",
        href: "https://www.cablelabs.com/privacy-policy",
        target: "_blank",
        link: "Learn more",
      },
      elements: {
        messagelink:
          '<span id="cookieconsent:desc" class="cc-message">' +
          "{{message}} " +
          '<a aria-label="learn more about cookies" role=button tabindex="0" class="cc-link" href="{{href}}" rel="noopener noreferrer nofollow" target="{{target}}">' +
          "{{link}}" +
          "</a>." +
          "</span>",
      },
      type: "info", // Info type compliance tells users that the site use cookies and by continuing to use the website, they accept it
      palette: {
        popup: {
          background: "#000000",
        },
        button: {
          background: "#f1242f",
        },
      },
      layouts: {
        basic: "{{messagelink}}{{compliance}}",
      },
      layout: "basic",
    });
  },
};
</script>
