export default {
  methods: {
    getFormattedDate(date, includeYear = true) {
      // Check if the date is a timestamp and remove time if so
      date = date.includes("T") ? date.substring(0, date.indexOf("T")) : date;
      date = new Date(date);
      date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
      const month = date.toLocaleString("default", {
        month: "short",
      });
      const yearStr = includeYear ? `, ${date.getFullYear()}` : "";

      return `${month} ${date.getDate()}${yearStr}`;
    },
    formatEventDate(startDate, endDate) {
      let dateToDisplay = "";
      if (startDate.indexOf("-") === -1) {
        startDate =
          startDate.slice(0, 4) +
          "-" +
          startDate.slice(4, 6) +
          "-" +
          startDate.slice(6, 8);
      }
      const formattedStartDate = this.getFormattedDate(startDate, false);

      // Handle multi-day events
      if (endDate.indexOf("-") === -1) {
        endDate =
          endDate.slice(0, 4) +
          "-" +
          endDate.slice(4, 6) +
          "-" +
          endDate.slice(6, 8);
      }
      const formattedEndDate = this.getFormattedDate(endDate, false);

      const [endDateMonth, endDateDay] = formattedEndDate
        ? formattedEndDate.split(" ")
        : null;
      const [startDateMonth, startDateDay] = formattedStartDate.split(" ");

      if (formattedEndDate && startDateMonth !== endDateMonth) {
        // Multi-day event that spans two different months should display month
        // for both start/end dates, e.g. Jan 29 - Feb 2
        dateToDisplay = `${formattedStartDate} - ${formattedEndDate}`;
      } else if (
        formattedEndDate &&
        startDateMonth === endDateMonth &&
        startDateDay !== endDateDay
      ) {
        // Multi-day event that does not span different months should only display
        // month for start date and not end date, e.g. Jan 1 - 3
        dateToDisplay = `${formattedStartDate} - ${endDateDay}`;
      } else {
        // Single day event should show just the start date
        dateToDisplay = formattedStartDate;
      }
      return dateToDisplay;
    },
  },
};
