import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import modules from "./modules";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  strict: true,
  plugins: [vuexLocal.plugin],
  mutations: {
    resetStore(state, payload) {
      for (const moduleName of Object.keys(modules)) {
        const moduleNameTitleCase =
          moduleName.charAt(0).toUpperCase() + moduleName.slice(1);

        if (modules[moduleName] && modules[moduleName].mutations) {
          this.commit(
            `${moduleName}/reset${moduleNameTitleCase}Store`,
            payload,
          );
        }
      }
    },
  },
  modules,
});
