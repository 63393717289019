<template>
  <transition name="fade">
    <page-loader v-if="loading" />
  </transition>
  <template v-if="!loading">
    <template v-if="$store.state.app.showDashboardNav">
      <dashboard-header />
    </template>
    <main
      class="main-content"
      :class="{
        'main-content--dashboard': $store.state.app.showDashboardNav,
        'main-content--basic': !$store.state.app.showDashboardNav,
      }"
    >
      <template v-if="$store.state.app.showDashboardNav">
        <main-nav componentClass="main-nav--desktop" />
      </template>
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </main>
    <cookie-consent />
  </template>
</template>

<style lang="scss">
@use "@/scss/front/global";
@import url("//use.typekit.net/fis2cku.css");
</style>

<script>
import CookieConsent from "@/components/CookieConsent";
import DashboardHeader from "@/components/DashboardHeader";
import MainNav from "@/components/MainNav";
import PageLoader from "@/components/PageLoader";
import FormatDate from "@/mixins/FormatDate";
import FormatStr from "@/mixins/FormatStr";
import GetData from "@/mixins/GetData";
import AccountService from "@/services/AccountService";
import GatedService from "@/services/GatedService";
import MarketingWordPressService from "@/services/MarketingWordPressService";
import SalesforceService from "@/services/SalesforceService";
import { mapAppFields } from "@/state/modules/app.js";
import { mapCompanyFields } from "@/state/modules/company.js";
import { mapEventsFields } from "@/state/modules/events";
import { mapFocalAreasFields } from "@/state/modules/focalAreas.js";
import { mapUserFields } from "@/state/modules/user.js";

export default {
  name: "CableLabs Account",

  components: {
    CookieConsent,
    DashboardHeader,
    MainNav,
    PageLoader,
  },

  mixins: [FormatDate, FormatStr, GetData],

  data() {},

  computed: {
    ...mapAppFields([
      "dataPulled",
      "firstVisitNewDashboard",
      "lastLogin",
      "loading",
      "userAuthenticated",
      "userAuthorized",
    ]),
    ...mapCompanyFields([
      "companyName",
      "companyType",
      "companyUserCount",
      "parentNdas",
      "vendorNdas",
    ]),
    ...mapEventsFields(["pastEvents", "upcomingEvents"]),
    ...mapFocalAreasFields(["focalAreas"]),
    ...mapUserFields(["vendorAwaitingApproval"]),
  },

  mounted() {
    this.$gtm.trackView("My CableLabs Account", "/");
  },

  watch: {
    $route() {
      this.getAllData();
    },
  },

  beforeUpdate() {
    this.removeAdPluggElements();
  },

  updated() {
    this.loadAdPluggJsFile();
  },

  methods: {
    closeNotification(notification) {
      notification.currentTarget.parentNode.classList.add(
        "notification--hidden",
      );
    },
    loadAdPluggJsFile() {
      // Add external AdPlugg JS (has to be added/removed on route change vs in the index.html to work)
      if (document.querySelector("[data-adplugg-access-code]")) {
        const externalScriptTag = document.createElement("script");

        externalScriptTag.src = "//www.adplugg.com/serve/js/ad.js";
        externalScriptTag.setAttribute("async", "");
        externalScriptTag.setAttribute("data-adplugg-external-js", "");

        document.body.appendChild(externalScriptTag);
      }
    },
    removeAdPluggElements() {
      // Undo everything AdPlugg does to the DOM before changing pages, otherwise the DOM gets filled every route change
      const externalScriptTag = document.querySelector(
        "[data-adplugg-external-js]",
      );

      if (externalScriptTag) {
        externalScriptTag.parentNode.removeChild(externalScriptTag);

        const imgTags = document.querySelectorAll(".adplugg-atb");
        const scriptTags = document.querySelectorAll("script");

        for (let i = 0; i < imgTags.length; i++) {
          imgTags[i].parentNode.removeChild(imgTags[i]);
        }

        for (let i = 0; i < scriptTags.length; i++) {
          if (scriptTags[i].src.includes("adplugg")) {
            scriptTags[i].parentNode.removeChild(scriptTags[i]);
          }
        }
        document.querySelector("html").classList.remove("no-flash");
      }
    },
    async getAllData() {
      this.loading = true;
      if (!this.dataPulled && this.userAuthenticated) {
        this.getUserData().then(() => {
          const hasAccess =
            this.$store.state.user.groups.includes("confluence-access") ||
            this.$store.state.user.groups.includes("cc-users");
          const hasDataInSf = this.$store.state.user.email;

          // If the user does not have data in salesforce, but is authorized to use the dashboard
          // reset store and redirect to Sign In with a notification letting them know to
          // check back in 20 min
          if (!hasDataInSf && hasAccess) {
            this.$store.commit("resetStore");
            this.$router.push({
              path: "/sign-in",
              params: {
                userMissingData: true,
              },
            });

            this.loading = false;

            // If the user does not have data in salesforce and is not authorized to use the dashboard
            // reset store and redirect to the Not Authorized page
          } else if (!hasDataInSf && !hasAccess) {
            this.$store.commit("resetStore");
            this.userAuthorized = false;

            if (this.$route.name !== "Sign In") {
              this.$router.push({
                path: "/not-authorized",
              });
            }
            this.loading = false;
          } else {
            this.getAppData().then(() => {
              this.dataPulled = true;
              this.loading = false;
            });
          }
        });
      } else {
        this.loading = false;
      }
    },
    async getAppData() {
      await Promise.allSettled([
        this.getCompanyCount(),
        this.getCompanyData(),
        this.getFocalAreas(),
        this.getLastLogin(),
        this.getNewDashboardVisit(),
        this.getEvents(),
      ]);
    },
    async getEvents() {
      let pastEventsResponse = [];
      let upcomingEventsResponse = [];

      const newDate = new Date();
      const todaysDate = new Date(newDate.toDateString());
      const oneYearAgo = new Date(
        newDate.setFullYear(newDate.getFullYear() - 1),
      );

      const wordPressResponse = await MarketingWordPressService.getEvents();

      if (wordPressResponse && wordPressResponse.data) {
        const events = wordPressResponse.data;

        for (let i = 0; i < events.length; i++) {
          if (events[i].acf.length <= 0) continue;

          let event = {};
          let startDate = events[i].acf.date?.start_date;
          const title = events[i].title?.rendered;

          if (!startDate || !title) continue;

          startDate =
            startDate.slice(0, 4) +
            "-" +
            startDate.slice(4, 6) +
            "-" +
            startDate.slice(6, 8);
          event.startDateInstance = new Date(startDate);

          // Don't include past events that are over one year old
          if (event.startDateInstance < oneYearAgo) continue;

          event.dateToDisplay = this.formatEventDate(
            events[i].acf.date?.start_date,
            events[i].acf.date?.end_date,
          );
          const venueName = events[i].acf.location?.venue_name;
          const eventFormat =
            events[i].acf.location?.format === "Virtual"
              ? "Virtual Event"
              : events[i].acf.location?.format;
          event.title = title;
          event.url = events[i].link;
          event.type = venueName
            ? venueName
            : eventFormat
              ? eventFormat
              : events[i].acf.location?.venue_city;
          event.image =
            events[i]._embedded?.[
              "wp:featuredmedia"
            ][0].media_details?.sizes?.medium_large?.source_url;

          if (event.startDateInstance < todaysDate) {
            pastEventsResponse.push(event);
          } else if (event.startDateInstance > todaysDate) {
            upcomingEventsResponse.push(event);
          }
        }
      }

      const gatedEventsResponse = await GatedService.events();
      if (gatedEventsResponse && gatedEventsResponse.data) {
        const gatedEvents = gatedEventsResponse.data.map((gatedEvent) => ({
          startDateInstance: new Date(gatedEvent.startDate),
          dateToDisplay: this.formatEventDate(
            gatedEvent.startDate,
            gatedEvent.endDate ? gatedEvent.endDate : gatedEvent.startDate,
          ),
          image: gatedEvent?.imageUrl,
          title: gatedEvent.title,
          url: gatedEvent?.url,
          type:
            gatedEvent.location === "Virtual"
              ? "Virtual Event"
              : gatedEvent.location,
        }));
        gatedEvents.filter(
          (gatedEvent) => new Date(gatedEvent.startDate) > oneYearAgo,
        );
        gatedEvents.forEach((gatedEvent) => {
          if (gatedEvent.startDateInstance < todaysDate) {
            pastEventsResponse.push(gatedEvent);
          } else if (gatedEvent.startDateInstance > todaysDate) {
            upcomingEventsResponse.push(gatedEvent);
          }
        });
      }

      this.pastEvents = pastEventsResponse.sort(
        (a, b) => b.startDateInstance - a.startDateInstance,
      );
      this.upcomingEvents = upcomingEventsResponse.sort(
        (a, b) => a.startDateInstance - b.startDateInstance,
      );
    },
    async getCompanyCount() {
      const response = await AccountService.companyUserCount(
        this.$store.state.user.username,
      );

      if (response && response.data) {
        this.companyUserCount = response.data[0]
          ? Number(response.data[0].companyUserCount) - 1
          : 0;
      }
    },
    async getFocalAreas() {
      const response = await GatedService.focalAreas();

      if (response && response.data) {
        let focalAreas = response.data;
        for (let i = 0; i < response.data.length; i++) {
          focalAreas[i].slug = this.slugify(focalAreas[i].focalArea);
        }
        this.focalAreas = focalAreas;
      }
    },
    async getLastLogin() {
      const response = await AccountService.lastLogin(
        this.$store.state.user.username,
      );

      if (response && response.data) {
        this.lastLogin = response.data[0]
          ? this.getFormattedDate(response.data[0].lastLogin)
          : "";
      }
    },
    async getNewDashboardVisit() {
      // Check to see if the user has logged into the new dashboard before
      const lookupResponse = await AccountService.lastVisit(
        this.$store.state.user.username,
      );

      if (lookupResponse && lookupResponse.data) {
        this.firstVisitNewDashboard = !(
          lookupResponse.data[0] && lookupResponse.data[0].clLoginC
        );

        // If the user has not logged into the new dashboard before, save their
        // username to the database so we know in the future that they have
        if (this.firstVisitNewDashboard) {
          await AccountService.saveLastVisit({
            clLoginC: this.$store.state.user.username,
          });
        }
      }
    },
    async getCompanyData() {
      const response = await SalesforceService.companyLookup({
        accountId: this.$store.state.company.accountId,
      });

      if (response && response.data) {
        this.companyName = response.data.companyName;
        this.companyType = response.data.companyType.toLowerCase();
        this.parentNdas = this.breakTagDelimStrToArr(response.data.parentNdas);
        this.vendorNdas = this.commaDelimStrToArr(response.data.vendorNdas);
        this.vendorAwaitingApproval =
          this.vendorNdas.length === 0 &&
          this.parentNdas.length === 0 &&
          this.companyType === "vendor";
      }
    },
    onClick() {
      this.$gtm.trackEvent({
        category: "Home Page",
        action: "click",
        label: "Home page click",
        value: 1,
      });
    },
  },
};
</script>
