<template>
  <nav class="main-nav" :class="componentClass">
    <div class="main-nav__list">
      <ul>
        <li class="main-nav__list-item">
          <router-link
            :to="{ name: 'Account Dashboard' }"
            class="main-nav__list-item-link main-nav__list-item-link--dashboard"
            active-class="main-nav__list-item-link--active"
          >
            Dashboard
          </router-link>
        </li>
        <accordion-wrapper>
          <accordion-item type="nav-item" route-when-open="focal-area">
            <template v-slot:accordion-trigger>
              <div
                class="main-nav__list-item-link main-nav__list-item-link--focal-areas"
                :class="{
                  'main-nav__list-item-link--active':
                    $route.name === 'Focal Area',
                }"
              >
                Focal Areas
              </div>
            </template>
            <template v-slot:accordion-content>
              <div v-for="focalArea in focalAreas" :key="focalArea">
                <router-link
                  :to="{
                    name: 'Focal Area',
                    params: { slug: focalArea.slug },
                  }"
                  class="main-nav__list-item-link main-nav__list-item-link--child"
                  :class="{
                    'main-nav__list-item-link--restricted':
                      !focalArea.permitted,
                  }"
                  active-class="main-nav__list-item-link--active"
                >
                  {{ focalArea.focalArea }}
                </router-link>
              </div>
            </template>
          </accordion-item>
        </accordion-wrapper>
        <li class="main-nav__list-item">
          <router-link
            :to="{ name: 'Events Index' }"
            class="main-nav__list-item-link main-nav__list-item-link--webinars-events"
            active-class="main-nav__list-item-link--active"
          >
            Webinars & Events
          </router-link>
        </li>
        <li class="main-nav__list-item">
          <a
            href="https://community.cablelabs.com/wiki/display/IZSP/White+Papers+and+Briefs"
            target="_blank"
            class="main-nav__list-item-link main-nav__list-item-link--white-papers"
          >
            White Papers
          </a>
        </li>
        <li class="main-nav__list-item">
          <a
            href="https://community.cablelabs.com/wiki/display/IZSP/Other+Resources"
            target="_blank"
            class="main-nav__list-item-link main-nav__list-item-link--white-papers"
          >
            Other Resources
          </a>
        </li>
      </ul>
      <hr />
      <ul>
        <li class="main-nav__list-item">
          <router-link
            :to="{ name: 'Help' }"
            class="main-nav__list-item-link main-nav__list-item-link--help"
            active-class="main-nav__list-item-link--active"
          >
            Help
          </router-link>
        </li>
        <li class="main-nav__list-item">
          <router-link
            :to="{ name: 'Email Subscriptions' }"
            class="main-nav__list-item-link main-nav__list-item-link--email-subscriptions"
            active-class="main-nav__list-item-link--active"
          >
            Email Subscriptions
          </router-link>
        </li>
        <li class="main-nav__list-item">
          <router-link
            :to="{ name: 'Account Profile' }"
            class="main-nav__list-item-link main-nav__list-item-link--account-profile"
            active-class="main-nav__list-item-link--active"
          >
            Account Profile
          </router-link>
        </li>
        <li class="main-nav__list-item">
          <a
            href="https://www.cablelabs.com/"
            target="_blank"
            class="main-nav__list-item-link main-nav__list-item-link--cablelabs"
          >
            CableLabs.com
          </a>
        </li>
        <li class="main-nav__list-item">
          <span
            @click="logout"
            class="main-nav__list-item-link main-nav__list-item-link--log-out"
          >
            Log out
          </span>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import AccordionItem from "@/components/AccordionItem";
import AccordionWrapper from "@/components/AccordionWrapper";
import LogoutService from "@/services/LogoutService";
import { mapFocalAreasFields } from "@/state/modules/focalAreas.js";

export default {
  title: "Main Nav",

  components: {
    AccordionWrapper,
    AccordionItem,
  },

  computed: {
    ...mapFocalAreasFields(["focalAreas"]),
  },

  props: {
    componentClass: {
      type: String,
      default: "",
    },
  },

  methods: {
    async logout() {
      this.$store.commit("app/updateLoading", true);
      const response = await LogoutService.logout();
      if (response) {
        this.$store.commit("resetStore");
        await this.$router.push({
          name: "Sign In",
        });
      } else {
        this.$store.commit("app/updateLoading", false);
      }
    },
  },
};
</script>
