import LoginStateService from "@/services/LoginStateService";
import { createRouter, createWebHistory } from "vue-router";
import store from "../state/index.js";

const AccountDashboard = () => import("@/views/AccountDashboard.vue");
const AccountProfile = () => import("@/views/AccountProfile.vue");
const EmailSubscriptions = () => import("@/views/EmailSubscriptions.vue");
const ErrorPage = () => import("@/views/ErrorPage.vue");
const EventsIndex = () => import("@/views/EventsIndex.vue");
const FocalArea = () => import("@/views/FocalArea.vue");
const HelpPage = () => import("@/views/HelpPage.vue");
const NotAuthorized = () => import("@/views/NotAuthorized.vue");
const SignIn = () => import("@/views/SignIn.vue");

const routes = [
  {
    path: "/account-profile",
    name: "Account Profile",
    component: AccountProfile,
    meta: {
      requiresAuth: true,
      showDashboardNav: true,
    },
  },
  {
    path: "/email-subscriptions",
    name: "Email Subscriptions",
    component: EmailSubscriptions,
    meta: {
      requiresAuth: true,
      showDashboardNav: true,
    },
  },
  {
    path: "/error",
    name: "Error",
    component: ErrorPage,
  },
  {
    path: "/webinars-and-events",
    name: "Events Index",
    component: EventsIndex,
    meta: {
      requiresAuth: true,
      showDashboardNav: true,
    },
  },
  {
    path: "/focal-area/:slug",
    name: "Focal Area",
    component: FocalArea,
    meta: {
      requiresAuth: true,
      showDashboardNav: true,
      requiresFocalAreaAccess: true,
    },
  },
  {
    path: "/help",
    name: "Help",
    component: HelpPage,
    meta: {
      requiresAuth: true,
      showDashboardNav: true,
    },
  },
  {
    path: "/not-authorized",
    name: "Not Authorized",
    component: NotAuthorized,
  },
  {
    path: "/",
    name: "Account Dashboard",
    component: AccountDashboard,
    meta: {
      requiresAuth: true,
      showDashboardNav: true,
    },
  },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Account Dashboard",
    component: AccountDashboard,
    meta: {
      requiresAuth: true,
      showDashboardNav: true,
    },
  },
  // Redirects
  {
    path: "/project-groups",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name !== "Error") {
    const response = await LoginStateService.checkLoginState();
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresApproval = to.matched.some(
      (record) => record.meta.requiresApproval,
    );
    const userAuthenticated = !!(response && response.data.valid);

    if (userAuthenticated) {
      store.commit("app/updateUserAuthenticated", true);
    } else {
      store.commit("app/updateUserAuthenticated", false);
    }

    if (requiresAuth && !userAuthenticated) {
      store.commit("resetStore");
      next("sign-in");
    } else if (
      requiresApproval &&
      store.state.user.vendorAwaitingApproval &&
      userAuthenticated
    ) {
      next("/");
    } else {
      next();
    }
  } else {
    store.commit("app/updateUserAuthenticated", false);

    if (store.state.app.errorOccurred) {
      store.commit("app/updateErrorOccurred", false);
      next();
    } else if (!store.state.app.errorOccurred) {
      next("/");
    }
  }
});

router.afterEach((to) => {
  const showDashboardNav = to.matched.some(
    (record) => record.meta.showDashboardNav,
  );

  if (store.state.app.mobileNavVisible) {
    setTimeout(() => {
      store.commit("app/updateMobileNavVisible", false);
    }, 300);
  }

  if (showDashboardNav) {
    store.commit("app/updateShowDashboardNav", true);
  } else {
    store.commit("app/updateShowDashboardNav", false);
  }
});

export default router;
