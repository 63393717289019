import AccountApi from "@/services/AccountApi";

export default {
  companyUserCount(username) {
    return AccountApi.get(
      `/account/company-count?filter[where][clLoginC]=${username}`,
    );
  },
  emailBlacklistLookup(emailStart) {
    return AccountApi.get(
      `/blacklist/email?filter[where][emailStart]=${emailStart}`,
    );
  },
  workgroups(username) {
    return AccountApi.get(
      `/account/majordomo?filter[where][clLoginC]=${username}`,
    );
  },
  lastLogin(username) {
    return AccountApi.get(
      `/account/last-login?filter[where][clLoginC]=${username}`,
    );
  },
  lastVisit(username) {
    return AccountApi.get(
      `/account/last-visit?filter[where][clLoginC]=${username}`,
    );
  },
  saveLastVisit(username) {
    return AccountApi.post("/account/last-visit", username);
  },
  saveUserData(userData) {
    Object.keys(userData).forEach((key) => {
      if (!userData[key] && userData[key] !== false) {
        delete userData[key];
      }
    });
    return AccountApi.post(`/account`, userData);
  },
};
