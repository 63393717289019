<template>
  <nav class="dashboard-header">
    <div class="dashboard-header__wrapper">
      <div class="dashboard-header__cablelabs-logo-wrapper">
        <img
          src="@/assets/cablelabs-logo.svg"
          alt="CableLabs Logo"
          class="dashboard-header__cablelabs-logo"
        />
      </div>
      <div class="dashboard-header__nav-wrapper">
        <ul class="dashboard-header-nav">
          <li
            class="dashboard-header-nav__item dashboard-header-nav__item--search dashboard-header-nav__item--has-subnav"
          >
            <div class="dashboard-header-nav__details">
              <div class="dashboard-header-nav__name">
                <img
                  src="@/assets/icons/magnifying-glass.svg"
                  alt="Search icon"
                  class="dashboard-header-nav__icon"
                />
              </div>
              <ul class="dashboard-header-nav__dropdown">
                <li class="dashboard-header-nav__list-item">
                  <a
                    href="https://tech-pubs-search.cablelabs.com/"
                    target="_blank"
                    class="dashboard-header-nav__list-item-link"
                  >
                    Search Tech Publications
                  </a>
                </li>
                <li class="dashboard-header-nav__list-item">
                  <a
                    href="https://community.cablelabs.com/wiki/dosearchsite.action?cql=&includeArchivedSpaces=false"
                    target="_blank"
                    class="dashboard-header-nav__list-item-link"
                  >
                    Search Working Group Documents
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li
            class="dashboard-header-nav__item dashboard-header-nav__item--has-subnav dashboard-header-nav__item--hide-mobile"
          >
            <div class="dashboard-header-nav__details">
              <div class="dashboard-header-nav__avatar">
                {{ getFirstChar($store.state.user.firstName)
                }}{{ getFirstChar($store.state.user.lastName) }}
              </div>
              <div class="dashboard-header-nav__name">
                {{ $store.state.user.firstName }}
                {{ $store.state.user.lastName }}
              </div>
              <ul class="dashboard-header-nav__dropdown">
                <li class="dashboard-header-nav__list-item">
                  <router-link
                    :to="{ name: 'Account Dashboard' }"
                    class="dashboard-header-nav__list-item-link"
                  >
                    Dashboard
                  </router-link>
                </li>
                <li class="dashboard-header-nav__list-item">
                  <router-link
                    :to="{ name: 'Account Profile' }"
                    class="dashboard-header-nav__list-item-link"
                  >
                    Account Profile
                  </router-link>
                </li>
                <li class="dashboard-header-nav__list-item">
                  <span
                    @click="logout"
                    class="dashboard-header-nav__list-item-link"
                    >Log Out</span
                  >
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <div class="mobile-nav">
          <div
            class="mobile-nav__hamburger"
            @click="toggleMobileNav"
            aria-label="Toggle Mobile Menu"
            :class="{
              'mobile-nav__hamburger--open': mobileNavVisible,
              'mobile-nav__hamburger--closed': !mobileNavVisible,
            }"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div class="mobile-nav">
    <div
      class="mobile-nav__wrapper"
      :class="{
        'mobile-nav__wrapper--open': mobileNavVisible,
        'mobile-nav__wrapper--closed': !mobileNavVisible,
      }"
    >
      <main-nav componentClass="main-nav--mobile" />
    </div>
  </div>
</template>

<script>
import LogoutService from "@/services/LogoutService";
import MainNav from "@/components/MainNav";
import { mapAppFields } from "@/state/modules/app.js";

export default {
  name: "DashboardHeader",

  components: {
    MainNav,
  },

  computed: {
    ...mapAppFields(["mobileNavVisible"]),
  },

  methods: {
    getFirstChar(str) {
      return str.charAt(0);
    },
    async logout() {
      this.$store.commit("app/updateLoading", true);
      const response = await LogoutService.logout();
      if (response) {
        this.$store.commit("resetStore");
        this.$router.push({
          path: "/sign-in",
        });
      } else {
        this.$store.commit("app/updateLoading", false);
      }
    },
    toggleMobileNav() {
      this.mobileNavVisible = !this.mobileNavVisible;
      document.querySelector("html").style.overflowY = this.mobileNavVisible
        ? "hidden"
        : "auto";
    },
  },
};
</script>
