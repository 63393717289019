import ServerApi from "@/services/ServerApi";

export default {
  companyLookup(accountId) {
    return ServerApi.post("salesforce-company-lookup", accountId);
  },
  emailAltLookup(email) {
    return ServerApi.post("salesforce-email-alt-lookup", email);
  },
  emailLookup(email) {
    return ServerApi.post("salesforce-email-lookup", email);
  },
  usernameLookup(cablelabsLogin) {
    return ServerApi.post("salesforce-username-lookup", cablelabsLogin);
  },
};
