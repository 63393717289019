import { createGtm } from "@gtm-support/vue-gtm";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import { defineRule } from "vee-validate";
import * as Vue from "vue";
import Vuex from "vuex";
import { Tabs, Tab } from "vue3-tabs-component";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./state";

const app = Vue.createApp(App).component("tabs", Tabs).component("tab", Tab);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["*"],
    }),
  ],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
  logErrors: true,
  trackComponents: true,
  hooks: ["activate", "create", "destroy", "mount", "update"],
});

defineRule("alphaExt", (value) => {
  if (
    value &&
    !/^[A-Za-z\u00c0-\u00ff}][A-Za-z\u00c0-\u00ff'-]+([ A-Za-z\u00c0-\u00ff][A-Za-z\u00c0-\u00ff'-]+)*/u.test(
      value,
    )
  ) {
    return "This field cannot contain special characters.";
  }

  return true;
});

defineRule("email", (value) => {
  if (!value || !value.length) {
    return true;
  }
  if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/.test(value)) {
    return "This field must be a valid email.";
  }
  return true;
});

defineRule("minMax", (value, [min, max]) => {
  if (!value || !value.length) {
    return true;
  }
  const numericValue = Number(value.length);
  if (numericValue < min) {
    return `This field must contain more than ${min} characters`;
  }
  if (numericValue > max) {
    return `This field must contain less than ${max} characters`;
  }
  return true;
});

defineRule("required", (value) => {
  if (!value || !value.length) {
    return "This field is required.";
  }
  return true;
});

app
  .use(store)
  .use(router)
  .use(Vuex)
  .use(
    createGtm({
      id: process.env.VUE_APP_GTM_ID,
      defer: false,
      compatibility: true,
      debug: process.env.NODE_ENV !== "production",
      vueRouter: router,
      trackOnNextTick: true,
    }),
  )
  .mount("#app");
