import GatedApi from "@/services/GatedApi";

export default {
  events() {
    return GatedApi.get(`/event`);
  },
  focalAreas() {
    return GatedApi.get(`/focal-area`);
  },
  focalAreaMajordomos(focalAreaId) {
    return GatedApi.get(`/focal-area/${focalAreaId}/majordomos`);
  },
  focalAreaProjects(focalAreaId) {
    return GatedApi.get(`/project?filter[where][focalAreaId]=${focalAreaId}`);
  },
};
