import { createHelpers, getField, updateField } from "vuex-map-fields";

export const { mapFields: mapEventsFields } = createHelpers({
  getterType: "events/getField",
  mutationType: "events/updateField",
});

function initialEventsState() {
  return {
    pastEvents: [],
    upcomingEvents: [],
  };
}
export default {
  namespaced: true,
  state: initialEventsState,
  getters: {
    getField,
  },
  mutations: {
    updateField,
    resetEventsStore(state) {
      const s = initialEventsState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
};
